import * as React from 'react';
import { defaultGetStaticProps } from 'utils/staticPropsHelper';
import dynamic from 'next/dynamic';
import View from 'components/View';

const NotFoundContainer = dynamic(() => import('containers/NotFound'));

const NotFound: React.FC = () => {
  return (
    <View className="404 text-center w-full">
      <NotFoundContainer />
    </View>
  );
};

export default NotFound;

export const getStaticProps = defaultGetStaticProps;
